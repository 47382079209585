.EllipsisText {
  display: inline-flex;
  min-inline-size: 0;
  max-inline-size: 100%;
}

.EllipsisText__content {
  min-inline-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 0 0 auto;
  max-inline-size: inherit;
}
