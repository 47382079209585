.Switch {
  /* Switch__track */
  --vkui_internal--Switch__track_shape: calc(var(--vkui_internal--Switch__track_width) / 2);
  --vkui_internal--Switch__track_outline_width: 2px;
  --vkui_internal--Switch__handle_shift_x: var(--vkui_internal--Switch__track_outline_width);
  /* prettier-ignore */
  --vkui_internal--Switch__handle_shift_y: calc(var(--vkui_internal--Switch__track_height) - var(--vkui_internal--Switch__handle_height) - var(--vkui_internal--Switch__track_outline_width));
  /* prettier-ignore */
  --vkui_internal--Switch__handle_scale: calc(var(--vkui_internal--Switch__handle_widthNoUnit--not-checked) / var(--vkui_internal--Switch__handle_heightNoUnit));

  position: relative;
  display: block;
  border-radius: var(--vkui_internal--Switch__track_shape);
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.Switch--disabled {
  cursor: not-allowed;
  opacity: var(--vkui_internal--Switch_disabled);
}

.Switch--default {
  --vkui_internal--Switch_disabled: 0.5;
  /* Switch__track */
  --vkui_internal--Switch__track_width: 52px;
  --vkui_internal--Switch__track_height: 32px;
  --vkui_internal--Switch__track_color: var(--vkui--color_background);
  --vkui_internal--Switch__track_color_duration: 67ms;
  /* prettier-ignore */
  --vkui_internal--Switch__track_shadow: inset 0 0 0 var(--vkui_internal--Switch__track_outline_width) var(--vkui--color_field_border_alpha);

  /* Switch__handle */
  --vkui_internal--Switch__handle_width: 28px;
  --vkui_internal--Switch__handle_widthNoUnit--checked: 24;
  --vkui_internal--Switch__handle_widthNoUnit--not-checked: 16;
  --vkui_internal--Switch__handle_height: 28px;
  --vkui_internal--Switch__handle_heightNoUnit: 28;
  --vkui_internal--Switch__handle_color: var(--vkui--color_icon_medium);
  --vkui_internal--Switch__handle_color_duration: 67ms;
  --vkui_internal--Switch__handle_scale_duration: 250ms;
  --vkui_internal--Switch__handle_shift_x_duration: 300ms;
  --vkui_internal--Switch__handle_shift_x_easing: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --vkui_internal--Switch__handle_shadow: none;
}

.Switch--default.Switch--sizeY-compact {
  /* Switch__track */
  --vkui_internal--Switch__track_width: 42px;
  --vkui_internal--Switch__track_height: 26px;

  /* Switch__handle */
  --vkui_internal--Switch__handle_width: 22px;
  --vkui_internal--Switch__handle_widthNoUnit--checked: 20;
  --vkui_internal--Switch__handle_widthNoUnit--not-checked: 12;
  --vkui_internal--Switch__handle_height: 22px;
  --vkui_internal--Switch__handle_heightNoUnit: 22;
}

@media (--sizeY-compact) {
  .Switch--default.Switch--sizeY-none {
    /* Switch__track */
    --vkui_internal--Switch__track_width: 42px;
    --vkui_internal--Switch__track_height: 26px;

    /* Switch__handle */
    --vkui_internal--Switch__handle_width: 22px;
    --vkui_internal--Switch__handle_widthNoUnit--checked: 20;
    --vkui_internal--Switch__handle_widthNoUnit--not-checked: 12;
    --vkui_internal--Switch__handle_height: 22px;
    --vkui_internal--Switch__handle_heightNoUnit: 22;
  }
}

.Switch--ios {
  --vkui_internal--Switch_disabled: 0.7;
  /* Switch__track */
  --vkui_internal--Switch__track_width: 51px;
  --vkui_internal--Switch__track_height: 31px;
  --vkui_internal--Switch__track_color: var(--vkui--color_track_background);
  --vkui_internal--Switch__track_color_duration: 200ms;
  --vkui_internal--Switch__track_shadow: none;

  /* Switch__handle */
  --vkui_internal--Switch__handle_width: 27px;
  --vkui_internal--Switch__handle_widthNoUnit--pressed: 27px;
  --vkui_internal--Switch__handle_widthNoUnit--not-checked: 27px;
  --vkui_internal--Switch__handle_height: 27px;
  --vkui_internal--Switch__handle_heightNoUnit: 27;
  --vkui_internal--Switch__handle_color: var(--vkui--color_icon_contrast);
  --vkui_internal--Switch__handle_color_duration: 200ms;
  --vkui_internal--Switch__handle_scale_duration: 200ms;
  --vkui_internal--Switch__handle_shift_x_duration: 200ms;
  --vkui_internal--Switch__handle_shift_x_easing: cubic-bezier(0.36, -0.24, 0.26, 1.32);
  /* prettier-ignore */
  --vkui_internal--Switch__handle_shadow:
    0 3px 8px rgba(0, 0, 0, 0.15),
    0 3px 1px rgba(0, 0, 0, 0.06),
    inset 0 0 0 0.5px rgba(0, 0, 0, 0.04);
}

.Switch--ios.Switch--sizeY-compact {
  /* Switch__track */
  --vkui_internal--Switch__track_width: 47px;
  --vkui_internal--Switch__track_height: 27px;

  /* Switch__handle */
  --vkui_internal--Switch__handle_width: 23px;
  --vkui_internal--Switch__handle_widthNoUnit--pressed: 23px;
  --vkui_internal--Switch__handle_widthNoUnit--not-checked: 23px;
  --vkui_internal--Switch__handle_height: 23px;
  --vkui_internal--Switch__handle_heightNoUnit: 23;
}

@media (--sizeY-compact) {
  .Switch--ios.Switch--sizeY-none {
    /* Switch__track */
    --vkui_internal--Switch__track_width: 47px;
    --vkui_internal--Switch__track_height: 27px;

    /* Switch__handle */
    --vkui_internal--Switch__handle_width: 23px;
    --vkui_internal--Switch__handle_widthNoUnit--pressed: 23px;
    --vkui_internal--Switch__handle_widthNoUnit--not-checked: 23px;
    --vkui_internal--Switch__handle_height: 23px;
    --vkui_internal--Switch__handle_heightNoUnit: 23;
  }
}

.Switch__inputFake {
  display: block;
}

.Switch__inputNative:checked + .Switch__inputFake {
  /* Switch__track */
  --vkui_internal--Switch__track_color: var(--vkui--color_background_accent);

  /* Switch__handle */
  /* prettier-ignore */
  --vkui_internal--Switch__handle_shift_x: calc(var(--vkui_internal--Switch__track_width) - var(--vkui_internal--Switch__handle_width) - var(--vkui_internal--Switch__track_outline_width));
  /* prettier-ignore */
  --vkui_internal--Switch__handle_scale: calc(var(--vkui_internal--Switch__handle_widthNoUnit--checked) / var(--vkui_internal--Switch__handle_heightNoUnit));
  --vkui_internal--Switch__handle_color: var(--vkui--color_icon_contrast);
}

.Switch--default:not(.Switch--disabled) .Switch__inputFake:active {
  /* Switch__handle */
  --vkui_internal--Switch__handle_scale: 1;
  --vkui_internal--Switch__handle_shift_x_easing: cubic-bezier(0.2, 0, 0, 1);
}

.Switch__track {
  position: relative;
  display: block;
  inline-size: var(--vkui_internal--Switch__track_width);
  block-size: var(--vkui_internal--Switch__track_height);
  border-radius: var(--vkui_internal--Switch__track_shape);
  background-color: var(--vkui_internal--Switch__track_color);
  box-shadow: var(--vkui_internal--Switch__track_shadow);
  transition: background-color var(--vkui_internal--Switch__track_color_duration) linear;
}

.Switch__handle {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  inline-size: var(--vkui_internal--Switch__handle_width);
  block-size: var(--vkui_internal--Switch__handle_height);
  border-radius: 9999px;
  /* prettier-ignore */
  transform: translate(var(--vkui_internal--Switch__handle_shift_x), var(--vkui_internal--Switch__handle_shift_y));
  /* prettier-ignore */
  transition: transform var(--vkui_internal--Switch__handle_shift_x_duration) var(--vkui_internal--Switch__handle_shift_x_easing);
}

.Switch__handle::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: inherit;
  background-color: var(--vkui_internal--Switch__handle_color);
  box-shadow: var(--vkui_internal--Switch__handle_shadow);
  transform: scale(var(--vkui_internal--Switch__handle_scale));
  transition-property: transform, background-color;
  transition-timing-function: var(--vkui_internal--Switch__handle_shift_x_easing), linear;
  /* prettier-ignore */
  transition-duration: var(--vkui_internal--Switch__handle_scale_duration), var(--vkui_internal--Switch__handle_color_duration);
}

.Switch__handle--withRipple::before {
  --vkui_internal--Switch__handleRipple_opacity: 0;
  --vkui_internal--Switch__handleRipple_opacity_duration: 15ms;
  --vkui_internal--Switch__handleRipple_color: var(--vkui--color_icon_medium);
  --vkui_internal--Switch__handleRipple_color_duration: 15ms;

  content: '';
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  inline-size: 40px;
  block-size: 40px;
  border-radius: inherit;
  background-color: var(--vkui_internal--Switch__handleRipple_color);
  opacity: var(--vkui_internal--Switch__handleRipple_opacity);
  transform: translate(-50%, -50%);
  transition-property: opacity, background-color;
  /* prettier-ignore */
  transition-duration:
    var(--vkui_internal--Switch__handleRipple_opacity_duration),
    var(--vkui_internal--Switch__handleRipple_color_duration);
  transition-timing-function: linear;
}

@media (--sizeY-compact) {
  .Switch__handle--withRipple::before {
    inline-size: 32px;
    block-size: 32px;
  }
}

/* устройства с мышкой */
@media (--hover-has) {
  .Switch__inputFake:hover .Switch__handle--withRipple::before {
    --vkui_internal--Switch__handleRipple_opacity: 0.08;
  }
}

.Switch__inputFake:active .Switch__handle--withRipple::before {
  --vkui_internal--Switch__handleRipple_opacity: 0.12;
}

.Switch__inputNative:checked + .Switch__inputFake .Switch__handle--withRipple::before {
  --vkui_internal--Switch__handleRipple_color: var(--vkui--color_background_accent);
}

/* тач-устройства */
@media (--hover-has-not) {
  .Switch__handle--withRipple::before {
    --vkui_internal--Switch__handleRipple_opacity_duration: 375ms;
  }

  .Switch__inputFake:active .Switch__handle--withRipple::before {
    --vkui_internal--Switch__handleRipple_opacity_duration: 105ms;
  }
}

/**
 * CMP:
 * SimpleCell
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalSimpleCell__after) > .Switch {
  margin-inline-start: 12px;
}
