.DateInput__input {
  display: block;
  flex-grow: 1;
  margin-inline: 10px 14px;
  z-index: var(--vkui_internal--z_index_form_field_element);
  cursor: text;
  white-space: nowrap;
  user-select: text;
  font-variant-numeric: tabular-nums;
}

.DateInput__input--time-divider {
  /* stylelint-disable-next-line declaration-no-important */
  letter-spacing: 6px !important;
}

.DateInput--sizeY-compact .DateInput__input {
  margin-inline-end: 22px;
}

@media (--sizeY-compact) {
  .DateInput--sizeY-none .DateInput__input {
    margin-inline-end: 22px;
  }
}
