.RichCellIcon {
  inline-size: 48px;
  block-size: 40px;
  box-sizing: border-box;
  color: var(--vkui--color_icon_accent_themed);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-block: 6px 6px;
  padding-inline: 12px 0;
}
