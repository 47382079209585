.DateRangeInput__input {
  flex-grow: 1;
  margin-inline: 10px 14px;
  z-index: var(--vkui_internal--z_index_form_field_element);
  cursor: text;
  white-space: nowrap;
  user-select: text;
  font-variant-numeric: tabular-nums;
}

.DateRangeInput--sizeY-compact .DateRangeInput__input {
  margin-inline-end: 22px;
}

@media (--sizeY-compact) {
  .DateRangeInput--sizeY-none .DateRangeInput__input {
    margin-inline-end: 22px;
  }
}
