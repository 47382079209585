.MiniInfoCell {
  display: flex;
  padding-block: 6px;
  padding-inline: var(--vkui--size_base_padding_horizontal--regular);
  color: var(--vkui--color_text_subhead);
}

.MiniInfoCell--mode-accent {
  color: var(--vkui--color_text_primary);
}

.MiniInfoCell__before {
  margin-inline-end: 12px;
  color: var(--vkui--color_icon_secondary);
}

.MiniInfoCell__middle {
  flex: 1;
  min-inline-size: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.MiniInfoCell__content {
  flex: 1;
  min-inline-size: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
}

.MiniInfoCell--textWrap-short .MiniInfoCell__content {
  white-space: initial;
  display: -webkit-box;
  max-block-size: 60px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.MiniInfoCell--textWrap-full .MiniInfoCell__content {
  overflow: inherit;
  white-space: initial;
}

.MiniInfoCell--textWrap-nowrap .MiniInfoCell__content {
  flex: 0 1 auto;
}

.MiniInfoCell__after {
  margin-inline-start: 12px;
  margin-block: -2px;
}

.MiniInfoCell--mode-add,
.MiniInfoCell--mode-more {
  color: var(--vkui--color_text_link_themed);
}

.MiniInfoCell--mode-add .MiniInfoCell__before,
.MiniInfoCell--mode-more .MiniInfoCell__before {
  color: var(--vkui--color_icon_accent_themed);
}

.MiniInfoCell--mode-more {
  padding-block: 10px;
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.MiniInfoCell__middle :global(.vkuiIcon) {
  margin-inline-start: 4px;
  color: var(--vkui--color_text_accent);
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.MiniInfoCell--mode-add .MiniInfoCell__middle :global(.vkuiIcon),
.MiniInfoCell--mode-more .MiniInfoCell__middle :global(.vkuiIcon) {
  color: var(--vkui--color_icon_accent_themed);
}
