.Search {
  display: flex;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  --vkui_internal--search_height: var(--vkui--size_search_height--regular);
}

.Search--withPadding {
  padding-block: 8px;
  padding-inline: var(--vkui--size_base_padding_horizontal--regular);
}

/*
  sizeY-compact
 */

.Search--sizeY-compact {
  --vkui_internal--search_height: var(--vkui--size_search_height--compact);
}

@media (--sizeY-compact) {
  .Search--sizeY-none {
    --vkui_internal--search_height: var(--vkui--size_search_height--compact);
  }
}

.Search__field {
  flex: 2;
  position: relative;
  display: flex;
  block-size: var(--vkui_internal--search_height);
  background-color: var(--vkui--color_search_field_background);
  border-radius: var(--vkui--size_border_radius--regular);
  /**
   * Костыль для PanelHeader. Необходимо для растягивания на всю ширину.
  */
  inline-size: 10000px;
  overflow: hidden;
}

.Search__field:hover {
  background-color: var(--vkui--color_search_field_background--hover);
}

.Search__field:active {
  background-color: var(--vkui--color_search_field_background--active);
}

.Search__label {
  position: absolute;
  cursor: text;
  inset: 0;
  opacity: 0;
}

.Search__input {
  padding-inline-start: 12px;
  color: var(--vkui--color_icon_medium);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  inline-size: calc(100% - 1px);
  block-size: 100%;
  border-radius: inherit;
  position: relative;
}

.Search__nativeInput {
  position: absolute;
  inset: 0;
  block-size: 100%;
  -webkit-appearance: none; /* не используем textfield из-за бага в iOS 15.1 http://www.openradar.me/FB9744107 */
  border: 0;
  margin: 0;
  padding: 0;
  padding-inline-start: 36px;
  box-sizing: border-box;
  border-radius: inherit;
  max-inline-size: 100%;
  flex-grow: 1;
  min-inline-size: 0;
  inline-size: stretch; /* автопрефиксер добавит фолбеки https://caniuse.com/mdn-css_properties_width_stretch */
  background-color: transparent;
  color: var(--vkui--color_text_primary);
}

.Search__nativeInput::-webkit-search-decoration,
.Search__nativeInput::-webkit-search-cancel-button,
.Search__nativeInput::-webkit-search-results-button,
.Search__nativeInput::-webkit-search-results-decoration {
  display: none;
}

.Search__nativeInput:focus {
  outline: var(--vkui_internal--outline-reset);
}

.Search--has-after .Search__nativeInput {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.Search--disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
  pointer-events: none;
  cursor: default;
}

.Search__nativeInput:disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Search__nativeInput::placeholder {
  color: var(--vkui--color_text_secondary);
  /* Для Firefox */
  opacity: 1;
}

.Search__nativeInput:disabled::placeholder {
  color: var(--vkui--color_text_secondary);
}

.Search__controls {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--vkui--color_icon_secondary);
  transition: transform 0.3s var(--vkui--animation_easing_platform);
  /* Используем translate3d, чтобы поправить дергание при наведении в Safari */
  transform: translate3d(100%, 0, 0);

  --vkui_internal--search_icon_size: calc(var(--vkui_internal--search_height) + 4px);
}

.Search--has-icon .Search__controls {
  /* Исключаем параметр icon из расчёта, чтобы он оставался видимым */
  transform: translate3d(calc(100% - var(--vkui_internal--search_icon_size)), 0, 0);
}

.Search--has-value .Search__controls {
  transform: translate3d(0, 0, 0);
}

.Search__icon {
  inline-size: var(--vkui_internal--search_icon_size);
  display: flex;
  align-items: center;
  justify-content: center;
  block-size: 100%;
  border-radius: var(--vkui--size_border_radius--regular);
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.Search__icon :global(.vkuiIcon) {
  padding: 0;
}

.Search__after {
  flex: 0;
  transform: translate(50%);
  transform-origin: left;
  min-inline-size: initial;
  max-inline-size: min-content;
  pointer-events: none;
  transition:
    flex 0.3s var(--vkui--animation_easing_platform),
    transform 0.3s var(--vkui--animation_easing_platform);
  overflow: hidden;
}

.Search--focused .Search__after,
.Search--has-value .Search__after {
  flex: 1;
  transform: translate(0);
  pointer-events: initial;
  margin-inline: 4px calc(4px - var(--vkui--size_base_padding_horizontal--regular));
}

.Search__afterText {
  display: block;
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
}

.Search__findButton {
  min-inline-size: initial;
  max-inline-size: initial;
  inline-size: initial;
}

/* FIXME поменять на `:global(.vkuiInternalGroup--mode-plain)` */
.Group--mode-plain .Search {
  padding-block-start: 4px;
}
