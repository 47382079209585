.Panel {
  position: relative;
  inline-size: 100%;
  block-size: 100%;
}

.Panel__in {
  position: relative;
  box-sizing: border-box;
  inline-size: 100%;
  min-block-size: 100%;
  display: flex;
  flex-direction: column;
}

.Panel__centered {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

/* stylelint-disable selector-max-universal */

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.Panel__centered :global(.vkuiInternalPanelHeader--static) + * {
  margin-block-start: var(--vkui_internal--panel_header_height);
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.Panel--sizeX-compact .Panel__centered :global(.vkuiInternalPanelHeader--sep) + * {
  margin-block-start: calc(var(--vkui_internal--panel_header_height) + 5px);
}

@media (--sizeX-compact) {
  /* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
  .Panel--sizeX-none .Panel__centered :global(.vkuiInternalPanelHeader--sep) + * {
    margin-block-start: calc(var(--vkui_internal--panel_header_height) + 5px);
  }
}

/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
.Panel--sizeX-regular .Panel__centered :global(.vkuiInternalPanelHeader--sep) + * {
  margin-block-start: calc(var(--vkui_internal--panel_header_height) + 16px);
}

@media (--sizeX-regular) {
  /* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
  .Panel--sizeX-none .Panel__centered :global(.vkuiInternalPanelHeader--sep) + * {
    margin-block-start: calc(var(--vkui_internal--panel_header_height) + 16px);
  }
}

/* stylelint-enable selector-max-universal */

/**
 * CMP:
 * View
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalView--ios) .Panel__in {
  padding-block: 0 var(--vkui_internal--safe_area_inset_bottom);
  padding-inline: var(--vkui_internal--safe_area_inset_left)
    var(--vkui_internal--safe_area_inset_right);
}

/**
 * CMP:
 * Epic
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalEpic--hasTabbar) .Panel__in {
  padding-block-end: var(--vkui_internal--tabbar_height);
  padding-block-end: calc(
    var(--vkui_internal--safe_area_inset_bottom) + var(--vkui_internal--tabbar_height)
  );
}

.Panel__in-before {
  block-size: 1px;
  margin-block-end: -1px;
}

.Panel__in-after {
  block-size: 1px;
  margin-block-start: -1px;
}

.Panel--mode-none .Panel__in,
.Panel--mode-plain .Panel__in {
  background: var(--vkui--color_background_content);
}

.Panel--mode-card .Panel__in {
  background: var(--vkui--color_background);
}

@media (--sizeX-regular) {
  .Panel--mode-none .Panel__in {
    background: var(--vkui--color_background);
  }
}
