.Snackbar {
  --vkui_internal--snackbar_safe_area_inset_bottom: var(--vkui_internal--safe_area_inset_bottom);
  --vkui_internal--snackbar_in_padding: 8px;
  --vkui_internal--snackbar_shift_x: 0;
  --vkui_internal--snackbar_shift_y: 0;
  --vkui_internal--snackbar_animation_from: translate3d(0, 0, 0);
  --vkui_internal--snackbar_animation_to: translate3d(0, var(--vkui_internal--snackbar_shift_y), 0);
  --vkui_internal--snackbar_animation_duration: 340ms;

  position: fixed;
  inset-inline: var(--vkui_internal--safe_area_inset_left)
    var(--vkui_internal--safe_area_inset_right);
  z-index: var(--vkui--z_index_popout);
  user-select: none;
}

.Snackbar--ios {
  --vkui_internal--snackbar_animation_duration: 400ms;
}

.Snackbar--placement-top-start,
.Snackbar--placement-top,
.Snackbar--placement-top-end {
  --vkui_internal--snackbar_animation_from: translate3d(0, -100%, 0);

  inset-block-start: var(--vkui_internal--safe_area_inset_top);
}

.Snackbar--placement-bottom-start,
.Snackbar--placement-bottom,
.Snackbar--placement-bottom-end {
  --vkui_internal--snackbar_animation_from: translate3d(0, 100%, 0);

  inset-block-end: var(--vkui_internal--snackbar_safe_area_inset_bottom);
}

@media (--desktop) {
  .Snackbar {
    inline-size: 100%;
    max-inline-size: 351px;
  }

  .Snackbar--placement-top,
  .Snackbar--placement-bottom {
    inset-inline: 0;
    margin-inline: auto;
  }

  .Snackbar--placement-top-start,
  .Snackbar--placement-top-end {
    inset-block-start: var(--vkui_internal--safe_area_inset_top);
  }

  .Snackbar--placement-bottom-start,
  .Snackbar--placement-bottom-end {
    inset-block-end: var(--vkui_internal--snackbar_safe_area_inset_bottom);
  }

  .Snackbar--placement-top-start,
  .Snackbar--placement-bottom-start {
    --vkui_internal--snackbar_animation_from: translate3d(-100%, 0, 0);

    inset-inline-start: var(--vkui_internal--safe_area_inset_left);
    inset-inline-end: auto;
  }

  .Snackbar--placement-top-end,
  .Snackbar--placement-bottom-end {
    --vkui_internal--snackbar_animation_from: translate3d(100%, 0, 0);

    inset-inline-start: auto;
    inset-inline-end: var(--vkui_internal--safe_area_inset_right);
  }

  .Snackbar--placement-top-start,
  .Snackbar--placement-bottom-start,
  .Snackbar--placement-top-end,
  .Snackbar--placement-bottom-end {
    --vkui_internal--snackbar_animation_to: translate3d(
      var(--vkui_internal--snackbar_shift_x),
      0,
      0
    );
  }
}

.Snackbar__in {
  padding: var(--vkui_internal--snackbar_in_padding);
  animation-duration: var(--vkui_internal--snackbar_animation_duration);
  animation-timing-function: var(--vkui--animation_easing_platform);
  animation-fill-mode: both;
  transform: translate3d(0, var(--vkui_internal--snackbar_shift_y), 0);
}

.Snackbar--state-enter .Snackbar__in,
.Snackbar--state-entering .Snackbar__in {
  animation-name: animation-snackbar-slide-in;
}

@media (--reduce-motion) {
  .Snackbar--state-enter .Snackbar__in,
  .Snackbar--state-entering .Snackbar__in {
    animation-name: animation-snackbar-fade-in;
  }
}

.Snackbar--state-entered .Snackbar__in {
  transition-property: transform;
  transition-duration: var(--vkui_internal--snackbar_animation_duration);
  transition-timing-function: var(--vkui--animation_easing_platform);
}

.Snackbar--touched .Snackbar__in {
  transition-property: none;
}

.Snackbar--state-exit .Snackbar__in,
.Snackbar--state-exiting .Snackbar__in {
  animation-name: animation-snackbar-slide-out;
}

@media (--reduce-motion) {
  .Snackbar--state-exit .Snackbar__in,
  .Snackbar--state-exiting .Snackbar__in {
    animation-name: animation-snackbar-fade-out;
  }
} /* stylelint-disable-next-line at-rule-empty-line-before */

@media (--desktop) {
  .Snackbar__in {
    --vkui_internal--snackbar_in_padding: 12px;
  }

  .Snackbar--placement-top-start .Snackbar__in,
  .Snackbar--placement-bottom-start .Snackbar__in,
  .Snackbar--placement-top-end .Snackbar__in,
  .Snackbar--placement-bottom-end .Snackbar__in {
    transform: translate3d(var(--vkui_internal--snackbar_shift_x), 0, 0);
  }
}

@keyframes animation-snackbar-slide-in {
  from {
    opacity: 0;
    transform: var(--vkui_internal--snackbar_animation_from);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes animation-snackbar-slide-out {
  from {
    opacity: 1;
    transform: var(--vkui_internal--snackbar_animation_to);
  }

  to {
    opacity: 0;
    transform: var(--vkui_internal--snackbar_animation_from);
  }
}

/* Анимации при (prefers-reduced-motion: reduce) */
@keyframes animation-snackbar-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes animation-snackbar-fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
