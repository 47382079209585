.ToolButton {
  position: relative;
  display: flex;
  box-sizing: border-box;
  text-decoration: none;
  border: 0;
  margin: 0;
  padding: 10px;
  border-radius: var(--vkui--size_border_radius_paper--regular);
  inline-size: 100%;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  justify-content: center;
  font-family: var(--vkui--font_caption1--font_family--regular);
  font-size: var(--vkui--font_caption1--font_size--regular);
  line-height: var(--vkui--font_caption1--line_height--regular);
}

.ToolButton--rounded {
  border-radius: var(--vkui--size_border_radius_rounded--regular);
}

.ToolButton--sizeY-regular {
  font-family: var(--vkui--font_subhead--font_family--regular);
  font-size: var(--vkui--font_subhead--font_size--regular);
  line-height: var(--vkui--font_subhead--line_height--regular);
}

@media (--sizeY-regular) {
  .ToolButton--sizeY-none {
    font-family: var(--vkui--font_subhead--font_family--regular);
    font-size: var(--vkui--font_subhead--font_size--regular);
    line-height: var(--vkui--font_subhead--line_height--regular);
  }
}

.ToolButton[disabled] {
  opacity: var(--vkui--opacity_disable);
}

/* ToolButton's directions */
.ToolButton--direction-row {
  flex-direction: row;
}

.ToolButton--direction-column {
  flex-direction: column;
}

.ToolButton--direction-row .ToolButton__text {
  margin-inline-start: 4px;
}

.ToolButton--direction-column .ToolButton__text {
  margin-block-start: 4px;
}

/* ToolButton's backgrounds */
/* Mode  = Primary */
.ToolButton--mode-primary.ToolButton--appearance-accent {
  background-color: var(--vkui--color_background_accent_themed);
}

.ToolButton--mode-primary.ToolButton--appearance-accent.ToolButton--hover {
  background-color: var(--vkui--color_background_accent_themed--hover);
}

.ToolButton--mode-primary.ToolButton--appearance-accent.ToolButton--active {
  background-color: var(--vkui--color_background_accent_themed--active);
}

.ToolButton--mode-primary.ToolButton--appearance-neutral {
  background-color: var(--vkui--color_background_secondary);
}

.ToolButton--mode-primary.ToolButton--appearance-neutral.ToolButton--hover {
  background-color: var(--vkui--color_background_secondary--hover);
}

.ToolButton--mode-primary.ToolButton--appearance-neutral.ToolButton--active {
  background-color: var(--vkui--color_background_secondary--active);
}

/* Mode = Secondary */
.ToolButton--mode-secondary {
  background-color: var(--vkui--color_background_secondary_alpha);
}

.ToolButton--mode-secondary.ToolButton--hover {
  background-color: var(--vkui--color_background_secondary_alpha--hover);
}

.ToolButton--mode-secondary.ToolButton--active {
  background-color: var(--vkui--color_background_secondary_alpha--active);
}

/* Mode = Tertiary */
.ToolButton--mode-tertiary,
.ToolButton--mode-outline {
  background-color: var(--vkui--color_transparent);
}

.ToolButton--mode-tertiary.ToolButton--hover,
.ToolButton--mode-outline.ToolButton--hover {
  background-color: var(--vkui--color_transparent--hover);
}

.ToolButton--mode-tertiary.ToolButton--active,
.ToolButton--mode-outline.ToolButton--active {
  background-color: var(--vkui--color_transparent--active);
}

/*
  ToolButtons text colors
*/
.ToolButton.ToolButton--appearance-accent {
  color: var(--vkui--color_text_accent_themed);
}

.ToolButton.ToolButton--appearance-neutral {
  color: var(--vkui--color_text_primary);
}

.ToolButton--mode-primary.ToolButton--appearance-accent {
  color: var(--vkui--color_text_contrast_themed);
}

/*
  Outline buttons borders
*/
.ToolButton--mode-outline.ToolButton--appearance-accent,
.ToolButton--mode-outline.ToolButton--appearance-accent.ToolButton--hover,
.ToolButton--mode-outline.ToolButton--appearance-accent.ToolButton--active {
  box-shadow: inset 0 0 0 1px var(--vkui--color_stroke_accent_themed);
}

.ToolButton--mode-outline.ToolButton--appearance-neutral {
  box-shadow: inset 0 0 0 1px var(--vkui--color_field_border_alpha);
}

.ToolButton--mode-outline.ToolButton--appearance-neutral.ToolButton--hover {
  background-color: var(--vkui--color_background_secondary--hover);
  box-shadow: unset;
}

.ToolButton--mode-outline.ToolButton--appearance-neutral.ToolButton--active {
  background-color: var(--vkui--color_background_secondary--active);
  box-shadow: unset;
}
