.DisplayTitle--level-1 {
  font-size: var(--vkui--font_display_title1--font_size--regular);
  line-height: var(--vkui--font_display_title1--line_height--regular);
  font-weight: var(--vkui--font_display_title1--font_weight--regular);
  font-family: var(--vkui--font_display_title1--font_family--regular);
}

.DisplayTitle--level-2 {
  font-size: var(--vkui--font_display_title2--font_size--regular);
  line-height: var(--vkui--font_display_title2--line_height--regular);
  font-weight: var(--vkui--font_display_title2--font_weight--regular);
  font-family: var(--vkui--font_display_title2--font_family--regular);
}

.DisplayTitle--level-3 {
  font-size: var(--vkui--font_display_title3--font_size--regular);
  line-height: var(--vkui--font_display_title3--line_height--regular);
  font-weight: var(--vkui--font_display_title3--font_weight--regular);
  font-family: var(--vkui--font_display_title3--font_family--regular);
}

.DisplayTitle--level-4 {
  font-size: var(--vkui--font_display_title4--font_size--regular);
  line-height: var(--vkui--font_display_title4--line_height--regular);
  font-weight: var(--vkui--font_display_title4--font_weight--regular);
  font-family: var(--vkui--font_display_title4--font_family--regular);
}
