.Counter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 5px;
  text-align: center;
  box-sizing: border-box;
}

.Counter--mode-primary {
  background: var(--vkui--color_background_accent);
  color: var(--vkui--color_text_contrast);
}

.Counter--mode-secondary {
  background: var(--vkui--color_icon_secondary);
  color: var(--vkui--color_text_contrast_themed);
}

.Counter--mode-prominent {
  background: var(--vkui--color_accent_red);
  color: var(--vkui--color_text_contrast);
}

.Counter--mode-contrast {
  background: var(--vkui--color_background_content);
  color: var(--vkui--color_text_accent_themed);
}

.Counter--size-m {
  block-size: 24px;
  min-inline-size: 24px;
  border-radius: 12px;
}

.Counter--size-s {
  block-size: 18px;
  min-inline-size: 18px;
  border-radius: 9px;
}

.Counter--mode-inherit {
  background-color: var(
    --vkui_internal--counter_inherit_background,
    var(--vkui--color_icon_secondary)
  );
  color: var(--vkui_internal--counter_inherit_color, var(--vkui--color_text_contrast_themed));
}

/**
 * CMP:
 * PanelHeaderButton
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalPanelHeaderButton) .Counter {
  position: absolute;
  padding: 0;
  inset-block-start: 8px;
  inset-inline-end: 2px;
}

/**
 * CMP:
 * TabbarItem
 */
/* stylelint-disable-next-line selector-pseudo-class-disallowed-list */
:global(.vkuiInternalTabbarItem__label) .Counter {
  position: absolute;
  inset-block-start: -2px;
  inset-inline-end: -9px;
}
