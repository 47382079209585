.Textarea {
  overflow: hidden;
  position: relative;
}

.Textarea__el {
  position: relative;
  z-index: var(--vkui_internal--z_index_form_field_element);
  display: block;
  padding: 12px;
  inline-size: 100%;
  max-block-size: 204px;
  box-sizing: border-box;
  color: var(--vkui--color_text_primary);
}

.Textarea__el[cols] {
  inline-size: initial;
}

.Textarea--sizeY-compact .Textarea__el {
  max-block-size: 196px;
}

@media (--sizeY-compact) {
  .Textarea--sizeY-none .Textarea__el {
    max-block-size: 196px;
  }
}

.Textarea__el::placeholder {
  color: var(--vkui--color_text_secondary);
  /* Для Firefox */
  opacity: 1;
}

.Textarea__el:disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Textarea__el:disabled::placeholder {
  color: var(--vkui--color_text_secondary);
}

.Textarea--align-center .Textarea__el {
  text-align: center;
}

.Textarea--align-right .Textarea__el {
  text-align: end;
}
