.Gradient {
  background: linear-gradient(to bottom, var(--vkui--gradient));
}

.Gradient.Gradient--to-bottom {
  background: linear-gradient(to top, var(--vkui--gradient));
}

.Gradient--mode-tint {
  background: linear-gradient(to bottom, var(--vkui--gradient_tint));
}

.Gradient--mode-tint.Gradient--to-bottom {
  background: linear-gradient(to top, var(--vkui--gradient_tint));
}
